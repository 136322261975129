<template>
  <div class="footer__top">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.footer__top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 56px;

  @media (min-width: $breakpoint-lg) {
    margin-bottom: 0;
    flex-direction: row;
  }
}
</style>
