<template>
  <div class="footer-socials">
    <BaseIconSvg class="footer-socials__logo" name="logo" />
    <div class="footer-socials__icons">
      <a
        href="https://www.facebook.com/Infermedica/"
        target="_blank"
        rel="noopener"
        class="footer-socials__social-media-icon facebook"
        ><span class="sr-only">Facebook link</span>
      </a>
      <a
        href="https://twitter.com/infermedica"
        target="_blank"
        rel="noopener"
        class="footer-socials__social-media-icon twitter"
        ><span class="sr-only">Twitter link</span>
      </a>
      <a
        href="https://www.linkedin.com/company/infermedica"
        target="_blank"
        rel="noopener"
        class="footer-socials__social-media-icon linkedin"
        ><span class="sr-only">LinkedIn link</span>
      </a>
      <a
        href="https://www.youtube.com/channel/UCCU6bFzXgismpw7Zu7Ygt7w"
        target="_blank"
        rel="noopener"
        class="footer-socials__social-media-icon youtube"
        ><span class="sr-only">YouTube link</span>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-socials {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 56px 0;

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0 24px 0;
  }

  &__logo {
    width: 240px;
    height: auto;

    @media (min-width: $breakpoint-lg) {
      width: 160px;
    }
  }

  &__icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__social-media-icon {
    & path {
      fill: var(--navy-300);
      transition: 0.2s ease;
    }
  }
}

.facebook {
  @include icon(
    '@/assets/icons/social-media/24-social-facebook-navy.svg',
    36px,
    36px
  );

  background-size: auto;
}

.twitter {
  @include icon(
    '@/assets/icons/social-media/24-social-twitter-navy.svg',
    36px,
    36px
  );

  background-size: 38px;
}

.linkedin {
  @include icon(
    '@/assets/icons/social-media/24-social-linkedin-navy.svg',
    36px,
    36px
  );

  background-size: auto;
}

.youtube {
  @include icon(
    '@/assets/icons/social-media/24-social-youtube-navy.svg',
    36px,
    36px
  );

  background-size: auto;
}
</style>
