<template>
  <footer v-if="footer" class="footer" :style="footerStyle">
    <div class="container">
      <FooterTop>
        <div class="footer-nav">
          <SbFooterItems :blok="footer.story.content" />
        </div>
      </FooterTop>
      <FooterMiddle />
    </div>
    <div class="footer__bottom-wrapper">
      <div class="container">
        <FooterBottom />
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
interface Props {
  footerColor: string | undefined
}
const { footerColor = '#fff' } = defineProps<Props>()

const storyblokApiInstance = useStoryblokApi()
const mainStore = useMainStore()
const { footer } = storeToRefs(mainStore)
const { locale } = useI18n()

const config = useRuntimeConfig()

const { data } = await useAsyncData(
  `footer-${locale.value}`,
  async () =>
    await storyblokApiInstance.get(`cdn/stories/global/newfooter`, {
      language: locale.value,
      version: config.public.storyblokApiVersion as 'draft' | 'published',
    }),
)
footer.value = data.value?.data

const footerStyle = computed(() => {
  return {
    'background-color': footerColor,
  }
})
</script>

<style lang="scss" scoped>
.footer {
  @include paragraph-s;

  padding-top: 80px;

  &__bottom-wrapper {
    background-color: var(--paper-100);
    padding-bottom: 80px;
  }
}

.container {
  @media (min-width: $breakpoint-md) {
    --container-padding: 54px;
  }

  @media (min-width: $breakpoint-lg) {
    --container-padding: 20px;
  }
}

.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: $breakpoint-lg) {
    justify-content: flex-start;
  }

  &__text {
    margin-right: 8px;
  }
}
</style>
