<template>
  <div class="footer__bottom container">
    <FooterSocials />
    <div class="footer__bottom-items">
      <NuxtLink
        :to="localePath('/privacy-policy')"
        class="footer__privacy-link"
      >
        {{ $t('common.footer.privacy_policy') }}
      </NuxtLink>
      <NuxtLink
        :to="localePath('/cookies-policy')"
        class="footer__privacy-link"
      >
        {{ $t('common.footer.cookies_policy') }}
      </NuxtLink>
      <div class="footer__copyright-text">
        &copy;&nbsp;Infermedica {{ currentYear }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const currentYear = computed(() => new Date().getFullYear())
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.footer {
  &__bottom {
    @include paragraph-xs;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;

    @media (min-width: $breakpoint-lg) {
      flex-direction: column;
      width: auto;
    }
  }
  &__bottom-items {
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
      gap: 40px;
      align-self: flex-end;
    }
  }

  &__privacy-link {
    @include paragraph-s;

    color: var(--color-text);

    &:hover {
      color: var(--color-link);
      text-decoration: none;
    }

    @media (min-width: $breakpoint-lg) {
      @include paragraph-xs;
    }
  }

  &__copyright-text {
    @include paragraph-s;

    display: flex;
    align-items: center;

    @media (min-width: $breakpoint-lg) {
      @include paragraph-xs;
    }
  }
}
</style>
